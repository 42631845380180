<template>
  <Toast />
  <NavBar
    :procMods="procMods"
    @module-switch="switchProcedureModule"
    v-if="user.isAuthenticated()"
  />
  <div
    :class="[
      'content container-fluid container-lg',
      isMobileDevice ? 'mobile-margin-top' : 'desktop-margin-top',
    ]"
  >
    <RouterView
      :key="$route.fullPath"
      v-on="$route.name === 'login' ? { login: handleLogin } : {}"
    />
  </div>
  <SessionExpiredModal v-if="user.isAuthenticated()" />
  <NoProcedureModulesModal
    v-if="user.isAuthenticated()"
    :visible="showNoProcedureModulesModal"
    @close="logout"
  />
  <NotesModal v-if="user.isAuthenticated()" />
  <NoteToasts v-if="user.isAuthenticated()" />
  <FooterBar />
</template>

<script>
import { removeModalBackdrop } from "@/services/modal";
import { useLoggedInUserStore } from "./store/loggedInUser";
import { procedureModuleStore } from "./store/procedureModule";
import { useMapStore } from "./store/map";
import { useNewsStore } from "./store/news";
import isMobile from "./composables/datatable/isMobile";
import {
  getProcedureModules,
  changeProcedureModule,
} from "@/services/apiCalls/procedure_module";
import NavBar from "./components/layout/navBar/NavBar.vue";
import FooterBar from "./components/layout/FooterBar.vue";
import SessionExpiredModal from "./components/ui/SessionExpiredModal.vue";
import NoProcedureModulesModal from "./components/ui/NoProcedureModulesModal.vue";
import NotesModal from "./components/ui/notes/NotesModal.vue";
import NoteToasts from "./components/ui/notes/NoteToasts.vue";
import Toast from "primevue/toast";

const MAX_NEWS_ITEMS = 500;

export default {
  name: "App",
  components: {
    NavBar,
    FooterBar,
    SessionExpiredModal,
    NoProcedureModulesModal,
    NotesModal,
    NoteToasts,
    Toast,
  },
  setup() {
    const user = useLoggedInUserStore();
    const activeProcMod = procedureModuleStore();
    const mapStore = useMapStore();
    const news = useNewsStore();
    const { isMobileDevice } = isMobile("lg");
    return {
      user,
      activeProcMod,
      mapStore,
      news,
      isMobileDevice,
    };
  },
  data() {
    return {
      procMods: [],
      noteFetchTimer: null,
      showNoProcedureModulesModal: false,
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    tempToken() {
      return this.$route.query.temp_token;
    },
    userId() {
      return this.user.user_id;
    },
  },
  methods: {
    async getProcMods() {
      try {
        this.procMods = await getProcedureModules();
      } catch (error) {
        console.error(error);
      }

      if (this.procMods.length === 0) {
        this.showNoProcedureModulesModal = true;
      }

      await this.activeProcMod.update();
    },
    async switchProcedureModule(mod_id) {
      try {
        this.procMods = await changeProcedureModule(mod_id);
        await this.activeProcMod.update();
        this.mapStore.forceFetchData();
        this.news.getAllNews(MAX_NEWS_ITEMS);
      } catch (err) {
        console.log(err);
      }
    },
    handleLogin() {
      this.mapStore.forceFetchData(); // In case user has a stale store, will still refresh
      this.setupUserData();
    },
    async setupUserData() {
      if (!this.user.isAuthenticated()) {
        return;
      }
      await this.getProcMods();

      this.mapStore.lazyFetchData();
      this.user.getNotes();
      this.news.getAllNews(MAX_NEWS_ITEMS);
    },
    logout() {
      this.showNoProcedureModulesModal = false;
      this.$router.push({ name: "logout" });
    },
  },
  created() {
    this.noteFetchTimer = setInterval(() => {
      if (this.user.isAuthenticated()) {
        this.user.getNotes();
      }
    }, 5000);

    // Prevent BaseModal backdrop from persisting when navigating pages with back button.
    window.addEventListener("popstate", removeModalBackdrop);
  },
  watch: {
    routeName() {
      if (this.user.isAuthenticated()) {
        this.user.updateInfo();
      }
    },
    async tempToken(tempToken) {
      if (!tempToken) {
        return;
      }

      try {
        await this.user.validateTempToken(tempToken);
      } catch (invalidTokenError) {
        return;
      }

      this.setupUserData();
    },
    userId: {
      handler() {
        this.setupUserData();
      },
      immediate: true,
    },
  },
};
</script>

<style>
@import "@/assets/base.css";
#app {
  font-family: nunito-sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  padding-top: 2rem;
}
.content {
  min-height: 85vh;
}
@media (min-width: 1024px) {
}

.mobile-margin-top {
  margin-top: 6.5rem;
}

.desktop-margin-top {
  margin-top: 3.25rem;
}
</style>
