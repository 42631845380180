<script setup>
import PrimevueDialog from "primevue/dialog";

const props = defineProps({
  visible: { type: Boolean, required: true },
});

const emit = defineEmits(["close"]);
function close() {
  emit("close");
}
</script>

<template>
  <PrimevueDialog
    modal
    :draggable="false"
    :visible="props.visible"
    @update:visible="close"
    style="max-width: 45rem"
  >
    <template #header>
      <h1 class="header">No Procedure Modules Assigned</h1>
    </template>
    <p>
      Your company or division does not have any assigned procedure modules.
      Procedure modules are required to use RepSignal. Please contact your
      manager or RepSignal Customer Success Representative.
    </p>
    <div class="d-flex justify-content-end">
      <button class="btn main-button-inverse" @click="close">Log out</button>
    </div>
  </PrimevueDialog>
</template>

<style scoped>
.header {
  color: var(--S2NDarkGrey);
}
</style>
